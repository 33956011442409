//Import Icon scss
@import "./assets/scss/icons.scss";

//import Light Theme
@import "./assets/scss/bootstrap.scss";
@import "./assets/scss/app.scss";

//Import Dark theme
// @import "./assets/scss/bootstrap-dark.scss";
// @import "./assets/scss/app-dark.scss";

// RTL mode
// @import "./assets/scss/bootstrap.scss";
// @import "./assets/scss/app-rtl.scss";

.accordian-bg-products {
  background-color: $gray-300;
}

.nav-link {
  cursor: pointer;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
  .pagination {
    .page-link {
      border-radius: 30px !important;
      margin: 0 3px;
      border: none;
      text-align: center;
      cursor: pointer;
    }
  }
}

.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 20px;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.react-bootstrap-table {
  padding-top: 0px !important;
}
.react-bs-table-no-data {
  text-align: center;
  font-weight: bold;
}

.square-switch {
  .react-switch-bg {
    border-radius: 5px !important;
  }
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polyline,
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-rectangle,
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-circle,
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-marker,
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-circlemarker,
.leaflet-draw-edit-remove,
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-remove {
  display: none;
}
@media only screen and (max-width: 500px) {
  .responsive-profile-container {
    display: block !important;
  }
  .responsive-profile {
    display: flex;
    width: 100% !important;
  }
  .responsive-profile-text {
    width: 100% !important;
    flex-flow: column;
    text-align: center;
  }
  .responsive-profile-text h5 {
    justify-content: center !important;
  }
  .button-container {
    padding: 0px !important;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .buttons {
    margin-left: 0 !important;
    width: 100%;
    justify-content: center;
  }
  .buttons .btn:nth-child(2) {
    padding: 10px !important;
  }
}
.sr-only {
  display: none;
}
input[type="range"] {
  -webkit-appearance: auto !important;
}

.chat-conversation-height {
  min-height: 500px;
}

.expand-cell {
  cursor: pointer !important;
}
.dashboard-date-dropdown {
  min-width: 180px;
  // background: white;
  // padding: 7px;
  // border-radius: 5px;
  cursor: pointer;
}
.dashboard-date {
  width: 180px !important;
}
.dashboard-date .dropdown-item:hover {
  background-color: #e6f6ee;
}
.dashboard-date .dropdown-item {
  cursor: pointer !important;
  padding-left: 10px !important;
  font-size: 12px;
  font-weight: bold;
}
.places-select__control {
  font-weight: 400 !important;
  color: #505d69 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
.places-select__option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.places-select__indicator.places-select__dropdown-indicator,
.places-select__indicators {
  display: none !important;
}
.places-select__input input {
  width: auto !important;
}
.logsTable {
  max-height: 400px;
  margin-bottom: 30px;
}
.rsis-container div {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: contain !important;
}
.z-index-999 {
  z-index: 999 !important;
}
.styled-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #e6f6ee;
  box-shadow: inset 0 0 6px #e6f6ee;
  border-radius: 10px;
}

.styled-scroll::-webkit-scrollbar {
  width: 3px;
  height: 9px;
}

.styled-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #6c757d2e;
  box-shadow: inset 0 0 6px #6c757d2e;
}
.react-time-picker__wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  height: calc(1.5em + 0.94rem + 2px);
  padding: 0.47rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #505d69;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.react-time-picker__inputGroup {
  width: max-content;
}

.trips .activity-wid .activity-list:before {
  display: none;
}

/* Styles for the CustomSelect component */
.CustomSelect .multi-select {
  display: inline-block;
  position: relative;
}

.CustomSelect .selected-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: slategrey;
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.CustomSelect .selected-option {
  display: inline-flex;
  align-items: center;
  margin-right: 0.5rem;
  padding: 0.25rem 0.5rem;
  background-color: #f0f0f0;
  border-radius: 4px;
  font-size: 0.875rem;
}

.CustomSelect .placeholder {
  color: #999;
}

.CustomSelect .remove-icon {
  margin-left: 0.25rem;
  cursor: pointer;
}

.CustomSelect .dropdown-icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: auto;
}

.CustomSelect .dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  max-height: 12rem;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
}

.CustomSelect .option {
  display: flex;
  padding: 0.5rem 1rem;
  cursor: pointer;
}
.CustomSelect .option input {
  margin-right: 5px;
}
.CustomSelect .option:hover {
  background-color: #f0f0f0;
}

.CustomSelect .option.selected {
  background-color: #cce5ff;
}

.CustomSelect .open .dropdown-options {
  display: block;
}
/* Customized CSS styles for Vehicle Cards */

.pkgVehicles .image-wrapper {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100px;
  vertical-align: middle;
  margin-top: -30px;
}

.pkgVehicles .vehicle-image {
  // width: 100%;
  // height: 100%;
  object-fit: cover;

  height: 100px;
  width: 100px;
  vertical-align: middle;
  border-radius: 50% !important;
}

.pkgVehicles .card-title {
  font-size: 1.2rem;
  font-weight: bold;
}
.pkgVehicles .card-body {
  padding: 10px;
}

.pkgVehicles .card-text {
  margin-bottom: 0.5rem;
  text-wrap: nowrap;
  align-items: center;
  display: flex;
}

.pkgVehicles .card-footer {
  border-top: none;
  padding: 5px;
}

.pkgVehicles .form-check-label {
  margin-left: 0.5rem;
}

.pkgVehicles .form-check-inline {
  display: inline;
}
.pkgVehicles .check-input {
  position: absolute;
  top: 10px;
  z-index: 1;
  right: 0;
  position: absolute;
}

/* Customized CSS styles for Driver Card */

.pkgVehicles .driver-card {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 0.5rem;
  background-color: #f8f9fa;
}

.pkgVehicles .driver-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 50%;
}

.pkgVehicles .driver-name {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0 !important;
}

.pkgVehicles .driver-info {
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}

/* Additional Customized Styles */

.pkgVehicles.container {
  margin-top: 2rem;
}

.pkgVehicles .my-4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pkgVehicles .mt-4 {
  margin-top: 1rem;
}

.pkgVehicles .row-cols-1 {
  flex-direction: row;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .pkgVehicles .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .pkgVehicles .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
.pkgVehicles .card {
  position: relative;
  cursor: pointer;
}

.pkgVehicles .card:hover {
  background-color: #08ff8b38;
}

.pkgVehicles .selected {
  background-color: #08ff8b38;
}
.pkgVehicles .disabled-vehicle {
  pointer-events: none;
  cursor: not-allowed;
}

// Route Module Form style
.bus-stop-title {
  position: sticky;
  top: 0;
  background-color: #28a745f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 20px;
  z-index: 1;
}
.bus-stop-list {
  display: flex;
  flex-direction: column;
  max-height: 60%;
  overflow-y: auto;
}

.bus-stop-item {
  display: flex;
  align-items: center;
}

.bus-stop-number {
  display: inline-block;
  width: 20px;
  text-align: center;
  font-weight: bold;
  margin: 0px 10px 7px 0;
}

.channel-wrap {
  display: flex;
  height: 100%;
}

.channel-list {
  float: left;
}

.channel-chat {
  width: 76%;
}
